import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';

import Container from '../components/global/container/Container';
import Layout from '../components/layout';
import { Grid, Cell } from '../components/global/grid';
import HomePages from '../queries/homePages';
import EstatesPages from '../queries/estatePages';
import CommercialPages from '../queries/commercialPages';
import FamilyPages from '../queries/familyPages';

const Sitemap = () => {
  const pages = useStaticQuery(graphql`
    query queryTwo {
      whoWeArePages: allWpPage(
        filter: { wpParent: { node: { slug: { eq: "who-we-are" } } } }
      ) {
        edges {
          node {
            id
            slug
            title
          }
        }
      }
      knowledgePages: allWpPage(
        filter: { wpParent: { node: { slug: { eq: "knowledge" } } } }
      ) {
        edges {
          node {
            id
            slug
            title
          }
        }
      }
      servicePages: allWpService {
        edges {
          node {
            id
            slug
            title
            wpChildren {
              nodes {
                id
              }
            }
          }
        }
      }
    }
  `);

  const Heading = styled.h1`
    padding-top: ${(props) =>
      props.paddingTop ? `${props.paddingTop}px` : '42px'};
    a {
      color: black;
      &:hover {
        color: #f58021;
      }
    }
  `;

  const Wrap = styled.div`
    padding-bottom: 64px;
  `;

  return (
    <Layout isBlue path="/sitemap">
      <Wrap>
        <Container>
          <Heading paddingTop={64}>Sitemap</Heading>

          <Grid>
            <Cell>
              <Heading as="h2">Our services</Heading>

              <Grid spacing={8} gutter={16}>
                {pages.servicePages.edges.length > 0 &&
                  pages.servicePages.edges
                    .filter((edge) => edge.node.wpChildren.nodes.length > 0)
                    .map((page) => (
                      <Cell cellSize={{ widescreen: 3, desktop: 3, tablet: 6 }}>
                        <Heading
                          paddingTop="0"
                          as="h3"
                          className="heading--no-padding"
                        >
                          <Link
                            to={`/our-services/${page.node.slug}`}
                            dangerouslySetInnerHTML={{
                              __html: page.node.title,
                            }}
                          />
                        </Heading>
                        <ul>
                          {(() => {
                            switch (page.node.slug) {
                              case 'your-home':
                                return <HomePages />;
                              case 'farms-estates':
                                return <EstatesPages />;
                              case 'business-commercial':
                                return <CommercialPages />;
                              case 'you-and-your-family':
                                return <FamilyPages />;
                              default:
                                return null;
                            }
                          })()}
                        </ul>
                      </Cell>
                    ))}
              </Grid>
            </Cell>

            <Cell
              cellSize={{
                widescreen: 3,
                desktop: 3,
                tablet: 6,
              }}
            >
              <Heading as="h2">
                <Link to="/who-we-are">Who we are</Link>
              </Heading>

              {pages.whoWeArePages.edges.length > 0 && (
                <ul>
                  {pages.whoWeArePages.edges.map((page) => (
                    <li key={page.node.id}>
                      <Link
                        to={`/who-we-are/${page.node.slug}`}
                        dangerouslySetInnerHTML={{ __html: page.node.title }}
                      />
                    </li>
                  ))}
                </ul>
              )}
            </Cell>
            <Cell
              cellSize={{
                widescreen: 3,
                desktop: 3,
                tablet: 6,
              }}
            >
              <Heading as="h2">
                <Link to="/covid-19">Covid-19</Link>
              </Heading>
            </Cell>
          </Grid>
        </Container>
      </Wrap>
    </Layout>
  );
};

export default Sitemap;
