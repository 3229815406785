import { graphql, useStaticQuery, Link } from 'gatsby';
import React from 'react';

const FamilyPages = () => {
  const familyPages = useStaticQuery(graphql`
    {
      allWpService(
        filter: { wpParent: { node: { slug: { eq: "you-and-your-family" } } } }
      ) {
        edges {
          node {
            id
            slug
            title
          }
        }
      }
    }
  `);
  return (
    <div>
      {familyPages.allWpService.edges.map((page) => (
        <li key={page.node.id}>
          <Link
            to={`/our-services/you-and-your-family/${page.node.slug}`}
            dangerouslySetInnerHTML={{ __html: page.node.title }}
          />
        </li>
      ))}
    </div>
  );
};

export default FamilyPages;
