import { graphql, useStaticQuery, Link } from 'gatsby';
import React from 'react';

const EstatesPages = () => {
  const estatePages = useStaticQuery(graphql`
    {
      allWpService(
        filter: { wpParent: { node: { slug: { eq: "farms-estates" } } } }
      ) {
        edges {
          node {
            id
            slug
            title
          }
        }
      }
    }
  `);
  return (
    <div>
      {estatePages.allWpService.edges.map((page) => (
        <li key={page.node.id}>
          <Link
            to={`/our-services/farms-estates/${page.node.slug}`}
            dangerouslySetInnerHTML={{ __html: page.node.title }}
          />
        </li>
      ))}
    </div>
  );
};

export default EstatesPages;
